<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        label="Search"
        label-size="sm"
        class="form-inline"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search partners"
          type="text"
        />
        <b-button
          v-b-modal.partnerModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Partner
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              dropright
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item
                @click="selectedRow = props.index; $bvModal.show('partnerModal')"
              >
                <feather-icon
                  class="mr-50"
                  icon="SendIcon"
                />
                <span>Send reminder</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedRow = props.index; $bvModal.show('sendMessageModel')"
              >
                <feather-icon
                  class="mr-50"
                  icon="SendIcon"
                />
                <span>Send message</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon
                  class="mr-50"
                  icon="EditIcon"
                />
                <span>Update status</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="`/startups/progressManager/partners/viewActions`"
              >
                <feather-icon
                  class="mr-50"
                  icon="EyeIcon"
                />
                <span>View action</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="partnerModal"
      size="lg"
      title="Application Form"
    >
      <PartnerFormBuilder
        v-model="applicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
    <b-modal
      id="sendMessageModel"
      size="lg"
      title="Send Message Form"
    >
      <SendMessageFormBuilder
        v-model="applicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
  </div>
</template>
<script>

import {
  BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BPagination, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import vSelect from 'vue-select'
import PartnerFormBuilder from '@/views/startups/progressManager/components/PartnerFormBuilder.vue'
import SendMessageFormBuilder from '@/views/startups/progressManager/components/SendMessageFormBuilder.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    PartnerFormBuilder,
    SendMessageFormBuilder,
    // vSelect,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Partner Title',
          field: 'partnerTitle',
        },
        {
          label: 'Purpose',
          field: 'purpose',
        },
        {
          label: 'Urgency level',
          field: 'urgencyLevel',
        },
        {
          label: 'Status',
          field: 'status',
        },
      ],
      rows: [{
        id: 1,
        partnerTitle: 'XYZ',
        purpose: 'XYZ',
        partnerAssigned: 'XYZ',
        urgencyLevel: 'XYZ',
        status: 'XYZ',
      }, {
        id: 2,
        partnerTitle: 'XYZ',
        purpose: 'XYZ',
        partnerAssigned: 'XYZ',
        urgencyLevel: 'XYZ',
        status: 'XYZ',
      }],
    }
  },
  computed: {
    assignPartner: {
      get() { if (this.rows.length < 1) { return [] } return Object.keys(this.rows[this.selectedRow].programs_partnerassignmenttables).map(key => this.rows[this.selectedRow].programs_partnerassignmenttables[key].partner_id) },
      set(e) { this.rows[this.selectedRow].programs_partnerassignmenttables = e.map(val => ({ partner_id: val, assignment_id: this.rows[this.selectedRow].id })) },
    },
    assignStartup: {
      get() { if (this.rows.length < 1) { return [] } return Object.keys(this.rows[this.selectedRow].programs_startupassignmenttables).map(key => this.rows[this.selectedRow].programs_startupassignmenttables[key].participant_id) },
      set(e) { this.rows[this.selectedRow].programs_startupassignmenttables = e.map(val => ({ participant_id: val, assignment_id: this.rows[this.selectedRow].id })) },
    },
  },
  methods: {
    updatePartner() {
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_partnerassignmenttable_insert_input!]!) {
          delete_programs_partnerassignmenttable(where: {assignment_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_partnerassignmenttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_partnerassignmenttables,
        },
      })
    },
    updateStartup() {
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_startupassignmenttable_insert_input!]!) {
          delete_programs_startupassignmenttable(where: {assignment_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_startupassignmenttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_startupassignmenttables,
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_assignmenttable(where: {programs_operationstable: {program_id: {_eq: ${this.$route.params.id}}}}) {
            id
            title
            type
            programs_operationstable {
              title
              operations_type
            }
            status
            programs_partnerassignmenttables {
              partner_id
              assignment_id
            }
            programs_startupassignmenttables {
              participant_id
              assignment_id
            }
          }
        }`
      },
      update: data => data.programs_assignmenttable,
    },
  },
}
</script>
