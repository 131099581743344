<template>
  <div>
    <b-form
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >
      <b-row>
        <b-col>
          <b-form-group
            class="mb-2"
            label="Subject"
            label-for="assignStage"
          >
            <b-form-input
              id="subject"
              v-model="subject"
              placeholder="Enter subject"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-2"
            label="Message"
            label-for="message"
          >
            <b-form-input
              id="message"
              v-model="message"
              placeholder="Enter message"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- class="mb-2" -->
          <b-form-group
            label="Select message mode"
            label-for="messageMode"
          >
            <!-- v-model="startups.selected.id"
            :options="startupsRows"
            :get-option-label="option => option.startups_basicinfo.title"
            :reduce="option => option.id" -->
            <v-select
              id="messageMode"
              :options="messageModeData"
              placeholder="Select message mode"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Send Reminder
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="reset"
            variant="primary"
          >
            Set Reminder
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row><b-col cols="auto">
            <h6 class="mt-25">
              Assignments
            </h6>
          </b-col><b-col><hr></b-col></b-row><vue-good-table
            :columns="columns"
            :rows="rows"
          /></b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: ['inheritedSections'],
  data() {
    return {
      sections: this.inheritedSections || [
        {
          id: 1,
          section: '',
          items: [{
            id: 1,
            question: '',
            input_type: '',
            prevHeight: 0,
          }],
        },
      ],
      messageModeData: [
        { id: 1, label: 'Msgmode 1' },
        { id: 2, label: 'Msgmode 2' },
        { id: 3, label: 'Msgmode 3' },
      ],
      nextTodoId: 2,
      subject: '',
      message: '',
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Subject',
          field: 'subject',
        },
        {
          label: 'Message',
          field: 'message',
        },
        {
          label: 'Reply',
          field: 'reply',
        },
      ],
      rows: [{
        id: 1,
        actionTitle: 'abc',
        team: 'abc',
        partnerAssigned: 'abc',
      }, {
        id: 2,
        actionTitle: 'abc',
        team: 'abc',
        partnerAssigned: 'abc',
      }],
    }
  },
  methods: {
    notifyParent() {
      this.$emit('input', this.sections)
    },
  },
}
</script>

<style lang="scss">
.vs__dropdown-menu{
  z-index: 1051 !important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
