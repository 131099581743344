<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >
          <!-- Resource Group tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Disbursement Rules"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                />
                <b-col md="12">
                  <b-row
                    v-for="(request, index) in requests"
                    :key="index"
                    class="mb-2"
                  >
                    <b-col>
                      <b-form-group
                        label="Enter Subject"
                        label-for="Enter Subject"
                      >
                        <b-form-input
                          id="requestTitle"
                          v-model="request.title"
                          placeholder="Enter Subject"
                          type="text"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Enter Message"
                        label-for="Enter Message"
                      >
                        <b-form-input
                          id="requestTitle"
                          v-model="request.title"
                          placeholder="Enter Message"
                          type="text"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label="Message Mode"
                        label-for="Message Mode"
                      >
                        <v-select
                          v-model="team"
                          :options="team"
                          :reduce="team => team.id"
                          label=" "
                          placeholder="Choose Message Mode"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
      <b-row>
        <b-col>
          <b-row><b-col cols="auto">
            <h6 class="mt-25">
              Messages
            </h6>
            <!-- :rows="partnerStageAssignments" -->
          </b-col><b-col><hr></b-col></b-row><vue-good-table
            :columns="messages"
            :rows="rows"
          /></b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    vSelect,
  },

  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      team: [],
      urgency: [],
      requests: [
        {
          title: null,
          milestoneAmount: null,
        },
      ],
      messages: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'Subject',
          field: 'subject',
        },
        {
          label: 'Message',
          field: 'message',
        },
        {
          label: 'Reply',
          field: 'reply',
        },
      ],
      rows: [],
    }
  },
  methods: {
    notifyParent() {
      this.$emit('input', this.sections)
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },

}
</script>

<style lang="scss">
.vs__dropdown-menu{
  z-index: 1051 !important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
