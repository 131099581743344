<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        label="Search"
        label-size="sm"
        class="form-inline"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search milestones"
          type="text"
        />
        <b-button
          v-b-modal.tapplicationModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Milestones
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              id="dropDownId"
              dropright
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item
                @click="selectedRow = props.index; $bvModal.show('criteriaModal')"
              >
                <feather-icon
                  class="mr-50"
                  icon="EyeIcon"
                />
                <span>View criterias</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="`/startups/viewReview/${props.row.id}`"
              >
                <feather-icon
                  class="mr-50"
                  icon="EyeIcon"
                />
                <span>View Review</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedRow = props.index; $bvModal.show('reviewModal')"
              >

                <feather-icon
                  class="mr-50"
                  icon="EditIcon"
                />
                <span>Create/Update review</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="`/startups/viewEvaluation/${props.row.id}`"
              >
                <!--@click="selectedRow = props.index; $bvModal.show('partnerModal')" -->

                <feather-icon
                  class="mr-50"
                  icon="BookOpenIcon"
                />
                <span>Evaluation</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedRow = props.index; $bvModal.show('linkMilestoneModal')"
              >

                <feather-icon
                  class="mr-50"
                  icon="LinkIcon"
                />
                <span>Link Milestone</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedRow = props.index; $bvModal.show('linkActionModal')"
              >

                <feather-icon
                  class="mr-50"
                  icon="Link2Icon"
                />
                <span>Link Action</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <!-- @click="selectedRow = props.index; $bvModal.show('startupModal')" -->

                <feather-icon
                  class="mr-50"
                  icon="BoxIcon"
                />
                <span> Update progress </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="tapplicationModal"
      size="lg"
      title="Milestone Add Form"
    >
      <MilestonesFormBuilder
        v-model="tapplicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
    <b-modal
      id="criteriaModal"
      size="lg"
      title="Criteria"
    >
      <CrieteriaFormBuilder
        v-model="tapplicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
    <b-modal
      id="reviewModal"
      size="lg"
      title="Review Form"
    >
      <ReviewFormBuilder
        v-model="tapplicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
    <b-modal
      id="linkMilestoneModal"
      size="lg"
      title="Link Milestone"
    >
      <LinkMilestoneFormBuilder
        v-model="tapplicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
    <b-modal
      id="linkActionModal"
      size="lg"
      title="Link Action"
    >
      <LinkActionFormBuilder
        v-model="tapplicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
  </div>
</template>
<script>

import {
  BButton, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import MilestonesFormBuilder from '@/views/startups/progressManager/components/MilestonesFormBuilder.vue'
import CrieteriaFormBuilder from '@/views/startups/progressManager/components/CrieteriaFormBuilder.vue'
import ReviewFormBuilder from '@/views/startups/progressManager/components/ReviewFormBuilder.vue'
import LinkMilestoneFormBuilder from '@/views/startups/progressManager/components/LinkMilestoneFormBuilder.vue'
import LinkActionFormBuilder from '@/views/startups/progressManager/components/LinkActionFormBuilder.vue'
// import gql from 'graphql-tag'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    MilestonesFormBuilder,
    CrieteriaFormBuilder,
    ReviewFormBuilder,
    LinkMilestoneFormBuilder,
    LinkActionFormBuilder,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Milestone Title',
          field: 'title',
        },
        {
          label: 'Milestone Type',
          field: 'milestoneType',
        },
        {
          label: 'Status',
          field(row) {
            if (!row.programs_operationstable) return '-'
            return row.programs_operationstable.title || '-'
          },
        },
      ],
      rows: [
        {
          id: 1,
          title: 'Milestone 1',
          milestoneType: 'M type 1',
          programs_operationstable: {
            title: 'status 1',
          },
        },
      ],
    }
  },
}
</script>

<style lang="scss">
#dropDownId > .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(7, 8, 12, 0.4);
    border-radius: 3px;

  &:hover {
    background: rgba(7, 8, 12,.4);
    }
  }
}
</style>
