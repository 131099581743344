<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >
          <!-- Resource Group tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Disbursement Rules"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                />
                <b-col md="12">
                  <b-row
                    v-for="(partner, index) in partners"
                    :key="index"
                    class="mb-2"
                  >
                    <b-col>
                      <b-form-group
                        label="Select partner"
                        label-for="Select partner"
                      >
                        <v-select
                          v-model="partn"
                          :options="milestones"
                          :reduce="partners => milestone.id"
                          label=" "
                          placeholder="Select partner"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Purpose"
                        label-for="Purpose"
                      >
                        <b-form-input
                          id="purpose"
                          v-model="partner.purpose"
                          type="number"
                          placeholder="Purpose"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Urgency level"
                        label-for="Urgency level"
                      >
                        <v-select
                          id=""
                          v-model="urgency"
                          :options="['High', 'Medium','Low']"
                          label=" "
                          placeholder="Choose Urgency"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationObserver,
    // BForm,
    BRow,
    BCol,
    // BButton,
    BFormGroup,
    BFormInput,
    // FormBuilder,
    vSelect,
  },

  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      partn: '',
      partners: [{
        actionTitle: null,
        urgencyLevel: null,
        deadline: null,
      }],
      milestone: '',
      milestones: ['m1'],
      designations: [
        {
          name: null,
        },
      ],
    }
  },
  // props: ['inheritedSections'],
  methods: {
    notifyParent() {
      this.$emit('input', this.sections)
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },

}
</script>

<style lang="scss">
.vs__dropdown-menu{
  z-index: 1051 !important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
