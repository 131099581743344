<template>
  <div>
    <b-form
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >
      <transition-group name="list">
        <b-row
          v-for="(section, index) in sections"
          :key="section.id"
          class="mb-2"
        >
          <b-col>
            <b-form-group
              :label="`Section ${index+1}`"
              :label-for="`section-${index+1}`"
            >
              <b-form-input
                :id="`section-${index+1}`"
                v-model="section.section"
                type="text"
                placeholder="Section title"
                required
                @change="notifyParent"
              />
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-button
              v-if="index === sections.length - 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 mt-2"
              @click="sections.push({
                id:Math.random(),
                section: '',
                items: [{
                  id: 1,
                  question: '',
                  input_type: '',
                  prevHeight: 0,
                }],
              })"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Section</span>
            </b-button>
            <b-button
              v-if="sections.length > 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2"
              @click="sections.splice(index, 1)"
            >
              <feather-icon
                class="mr-25"
                icon="XIcon"
              />
              <span>Remove</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
          >
            <transition-group name="list">
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in section.items"
                :id="item.id"
                :key="item.id"
                ref="row"
                class="ml-25 w-100 border-left py-1 pr-1 "
              >

                <!-- Item Name -->
                <b-col md="7">
                  <b-form-group
                    :label="`Question ${index+1}`"
                    :label-for="'question-'+item.id"
                  >
                    <b-form-input
                      :id="`question-${item.id}`"
                      v-model="item.question"
                      type="text"
                      placeholder="Question"
                      required
                      @change="notifyParent"
                    />
                  </b-form-group>
                </b-col>

                <!-- Answer -->
                <b-col>
                  <b-form-group
                    label="Data Type"
                    :label-for="`input_type-${item.id}`"
                  >
                    <v-select
                      :id="`input_type-${item.id}`"
                      v-model="item.input_type"
                      class="input_type"
                      :options="['Short text',
                                 'Long text',
                                 'Drop Down',
                                 'Number',
                                 'Email',
                                 'Website URL',
                                 'Document',]"
                      label="Data type"
                      append-to-body
                      placeholder="Select from list"
                      @change="notifyParent"
                    />
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col
                  cols="auto"
                  class="mb-50"
                >
                  <b-button
                    v-if="section.items.length > 1"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="section.items.splice(index, 1)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </b-col>
                <b-col cols="12"><b-button
                  v-if="index === section.items.length - 1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="mt-1"
                  @click="section.items.push({
                    id: Math.random(),
                    question: '',
                    input_type: '',
                    prevHeight: 0,
                  })"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add Question</span>
                </b-button></b-col>
              </b-row></transition-group>
          </b-col>
        </b-row>
      </transition-group>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: ['inheritedSections'],
  data() {
    return {
      sections: this.inheritedSections || [
        {
          id: 1,
          section: '',
          items: [{
            id: 1,
            question: '',
            input_type: '',
            prevHeight: 0,
          }],
        },
      ],
      nextTodoId: 2,
    }
  },
  methods: {
    notifyParent() {
      this.$emit('input', this.sections)
    },
  },
}
</script>

<style lang="scss">
.vs__dropdown-menu{
  z-index: 1051 !important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
