<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          name="milestoneAddForm"
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >
          <!-- Resource Group tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Disbursement Rules"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                />
                <b-col md="12">
                  <b-row
                    class="mb-2"
                  >
                    <b-col md="12">
                      <b-form-group
                        label="Select partner"
                        label-for="mentor"
                      >
                        <v-select
                          :options="partnerDropData"
                          :reduce="partnerDrop => partnerDrop.id"
                          placeholder="Choose partner"
                          @input="showResponsibility"
                          @change="showResponsibility"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-row class="ml-25 border-left">
                        <b-col
                          class="my-1"
                          cols="12"
                        >
                          <b-row>
                            <b-col
                              cols="auto"
                            ><h6 class="mt-25">
                              Responsibility Input Form
                            </h6></b-col>
                            <b-col>
                              <hr>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col
                          v-if="viewResponsibility"
                          md="12"
                        >
                          <transition-group name="list">
                            <b-row
                              v-for="(responsibility, idx) in responsibilities"
                              :key="responsibility.id"
                            >
                              <b-col>
                                <b-form-group
                                  :label="`Add Responsibility ${idx+1}`"
                                  :label-for="`areaTitle-${idx+1}`"
                                >
                                  <b-form-input
                                    :id="`areaTitle-${idx+1}`"
                                    v-model="responsibility.title"
                                    type="text"
                                    placeholder="Add Responsibility"
                                    required
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col cols="auto">
                                <b-button
                                  v-if="idx === responsibilities.length - 1"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2 mt-2"
                                  @click="responsibilities.push({
                                    id:Math.random(),
                                    title: '',
                                    items: [{
                                      id: 1,
                                      question: '',
                                      dataType: '',
                                      prevHeight: 0,
                                    }],
                                  })"
                                >
                                  <feather-icon
                                    icon="PlusIcon"
                                    class="mr-25"
                                  />
                                  <span>Add Responsibility</span>
                                </b-button>
                                <b-button
                                  v-if="responsibilities.length > 1"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="danger"
                                  class="mt-2"
                                  @click="responsibilities.splice(idx, 1)"
                                >
                                  <feather-icon
                                    class="mr-25"
                                    icon="XIcon"
                                  />
                                  <span>Remove</span>
                                </b-button>
                              </b-col>
                            </b-row></transition-group></b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'

export default {
  components: {
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
  },

  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      responsibilities: [
        {
          id: 1,
          title: '',
        },
      ],
      partners: [
        {
          title: null,
          purpose: null,
        },
      ],
      required,
      partnerDropData: [
        'Partner 1',
        'Partner 2',
      ],
      viewResponsibility: false,
    }
  },
  methods: {
    notifyParent() {
      this.$emit('input', this.sections)
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    showResponsibility(selectType) {
      if (selectType === null) {
        this.viewResponsibility = false
      } else {
        this.viewResponsibility = true
      }
    },
  },

}
</script>

<style lang="scss">
.vs__dropdown-menu{
  z-index: 1051 !important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
